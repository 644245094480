import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

import Quote from '../components/styles/Quote'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-bottom: 90px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: ${p => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const Heading = styled.h1`
  font-size: 30px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
  p:not(:last-child) {
    margin-bottom: 26px;
  }
  a {
    position: relative;
    z-index: 2;
    :after {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      bottom: 1px;
      background-color: ${p => p.theme.primaryColorLighter};
      width: 100%;
      height: 6px;
      z-index: -1;
    }
  }
`

const PortraitContainer = styled.div`
  :first-child {
    margin-bottom: 80px;
    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 210px;
  opacity: 0.4;
  z-index: -1;
  top: -24px;
  left: -40px;
  @media (max-width: 1000px) {
    width: 170px;
    left: -5px;
    top: -20px;
  }
`

const WatercolorWrapper = styled.div`
  position: relative;
  margin-top: 48px;
`

class YogaUndWeinRetreats extends Component {
  render() {
    const { yogaImage, watercolorImage, retreat } = this.props.data
    const retreatTitle = retreat?.edges?.[0]?.node?.frontmatter?.title
    const retreatHtml = retreat?.edges?.[0]?.node?.html

    return (
      <React.Fragment>
        <SEO
          title="Yoga & Wein Retreats"
          description="Vino Vinyasa Yoga – Kombination von Körper, Geist und Genuss"
          image="https://www.weingut-goeschl.at/yoga-und-wein.jpg"
        />
        <HeadingMain mainHeading="Yoga & Wein Retreats" />
        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Yoga & Wein Retreats</Heading>
            <Quote>„I do yoga to relieve stress. Just kidding. I drink wine in yoga pants.“</Quote>
            <Paragraph>
              1-2 Mal jährlich finden unsere Yoga & Wein Retreats statt. Dabei handelt es sich um ein verlängertes
              Wochenende (3-4 Nächte), das mit einem passenden Rahmenprogramm gestaltet wird. In Zusammenarbeit mit dem
              Weingut Achs-Wendelin in Gols oder dem Friedrichshof in Zurndorf bieten wir den Gästen Zimmer und
              Apartments als Übernachtungsmöglichkeit an. Bei beiden Locations wird ein umfangreiches Frühstück
              angeboten, das vorwiegend aus regionalen und biologischen Zutaten besteht.
            </Paragraph>
            <Paragraph>
              Im Fokus des Retreats steht das Yoga-Programm, bei dem täglich 2 Yogaeinheiten (morgens & abends)
              stattfinden. Der zweite Fokus liegt natürlich beim Wein, so gibt es eine gemeinsame Weinverkostung von
              ausgewählten Weinen sowie eine Führung durch unseren Betrieb. Dabei kann hautnah in die burgenländische
              Mentalität und Kultur eingetaucht werden. Zur Weinverkostung servieren wir eine klassische Heurigenjause.
            </Paragraph>
            <Paragraph>
              Genauso wichtig wird aber auch die Verbindung zur Natur integriert, so gibt es beispielsweise eine
              Weinwanderung durch die Golser Weinberge oder auch einen Ausflug in den Nationalpark Neusiedlersee.
              Weiters gibt es verschiedene “Überraschungs-Programmpunkte“.
            </Paragraph>
            {retreatTitle && (
              <>
                <WatercolorWrapper>
                  <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
                </WatercolorWrapper>
                <Heading>Vino Vinyasa Yoga Retreat 2023</Heading>
              </>
            )}
            {retreatHtml && (
              <Paragraph as="div">
                <div dangerouslySetInnerHTML={{ __html: retreatHtml }} />
              </Paragraph>
            )}
          </Left>
          <Right>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
              />
            </PortraitContainer>
          </Right>
        </Container>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default YogaUndWeinRetreats

export const query = graphql`
  {
    yogaImage: file(relativePath: { eq: "photos/retreat.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    retreat: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/yoga-retreat-info/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`
